import {
  collection,
  query,
  getDocs,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faShareFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import axios from "axios";
import { db } from "../../api/firebase";

const PowerPages = () => {
  const pageTitle = "Power Pages";
  const [pageDescription, setPageDescription] = useState("");

  const initDom: any = null;
  const [site, setSite] = useState(initDom);

  const params = useParams();
  const domainId = params.did ? params.did.toString() : "";

  const init: any[] = [];
  const [pages, setPages] = useState(init);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleConfirm = () => {
    setShow(false);
    onCalculateHandler();
  };

  useEffect(() => {
    loadDomain();
  }, []);

  const loadDomain = async () => {
    const doDocRef = doc(db, "site_monitor", domainId);
    onSnapshot(doDocRef, (doc) => {
      const site = doc.data() as any;
      site.id = domainId;
      setSite(site);
      setPageDescription(site.siteUrl);
    });
    loadPages();
  };

  const loadPages = async () => {
    const q = await getDocs(
      query(collection(db, "site_monitor/" + domainId + "/page_score"))
    );
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any;
      obj.id = doc.id;

      return obj;
    });

    setPages(
      objList.sort(function (a, b) {
        return b.score - a.score;
      })
    );
  };

  const onCalculateHandler = async () => {
    // const s = domains.find((e) => e.id === domainId);
    // if (!s.powerPageSumRange) {
    //   s.powerPageSumRange = "last28days"; //default value
    // }

    await updateDoc(doc(db, "site_monitor", site.id), {
      powerPageSumStatus: "validating",
      powerPageSumAt: "Validating...",
    });

    const r = await axios.post(
      process.env.REACT_APP_BACKEND_API_PATH + "gsc/calculatePowerPages",
      { domainid: site.id, range: site.powerPageSumRange, debug: 1 }
    );
    // const r = await axios.post(
    //   "http://localhost:5001/seo-header-tag/asia-southeast2/calculatePowerPages",
    //   { domainid: site.id, range: site.powerPageSumRange, debug: 1 }
    // );
    if (r.status === 200) {
      toast.success("This might take up to 5 - 30 minutes.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      await updateDoc(doc(db, "site_monitor", site.id), {
        powerPageSumStatus: "completed",
        powerPageSumAt: "Error",
      });
    }

    // recalculate();
  };

  return (
    <div>
      <Meta title={pageTitle} />
      {site && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure to calculate power pages for {site.name}?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleConfirm}>
              Yes, proceed
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {site && (
        <Card className="mb-5">
          <Card.Body>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 col-xl-8">
                <div>You are viewing</div>
                <div>
                  <h4 className="pt-3">{site.siteUrl}</h4>
                </div>
                <div>Power Pages</div>
                <div className="mt-3">
                  <Button
                    onClick={() => {
                      navigate("/domains");
                    }}
                    variant="primary"
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
                    Domains
                  </Button>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 col-xl-2 text-secondary text-center d-flex align-items-center">
                <div>
                  <div>
                    {site.powerPageSumStatus === "never" && (
                      <>
                        <div
                          className="text-info"
                          style={{ fontSize: "1.2em", fontWeight: "bold" }}
                        >
                          Never Calculate
                        </div>
                        <div>Press "Generate" button</div>
                      </>
                    )}
                    {site.powerPageSumStatus === "error" && (
                      <>
                        <span
                          className="text-danger"
                          style={{ fontSize: "1.2em", fontWeight: "bold" }}
                        >
                          Error!
                        </span>
                      </>
                    )}
                    {site.powerPageSumStatus === "completed" && (
                      <>
                        <span
                          className="text-success"
                          style={{ fontSize: "1.2em", fontWeight: "bold" }}
                        >
                          Completed
                        </span>
                      </>
                    )}
                    {site.powerPageSumStatus === "pending" && (
                      <>
                        <Spinner
                          className="me-1"
                          variant="warning"
                          animation="grow"
                          size="sm"
                        />{" "}
                        <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                          Calculating
                        </span>
                      </>
                    )}
                    {site.powerPageSumStatus === "validating" && (
                      <>
                        <Spinner
                          className="me-1"
                          variant="info"
                          animation="grow"
                          size="sm"
                        />{" "}
                        <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                          Validating
                        </span>
                      </>
                    )}
                  </div>
                  {site.powerPageSumStatus !== "never" && (
                    <div className="pt-2" style={{ fontSize: "0.8em" }}>
                      Last updated
                    </div>
                  )}
                  <div style={{ fontSize: "0.8em", fontWeight: "bold" }}>
                    {site.powerPageSumAt ? site.powerPageSumAt : "-"}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 col-xl-2 d-flex align-items-center">
                <div>
                  <div style={{ width: "100%" }} className="mb-2">
                    <Form.Select
                      disabled={
                        site.powerPageSumStatus === "pending" ||
                        site.powerPageSumStatus === "validating"
                      }
                      size="sm"
                      defaultValue={site.powerPageSumRange}
                      onChange={(e) => {
                        site.powerPageSumRange = e?.currentTarget?.value;
                      }}
                    >
                      <option value="last28days">Last 28 days</option>
                      <option value="last3months">Last 3 months</option>
                      <option value="last6months">Last 6 months</option>
                    </Form.Select>
                  </div>
                  <div style={{ width: "100%" }} className="mb-2">
                    <div className="d-grid gap-2">
                      <Button
                        disabled={
                          site.powerPageSumStatus === "pending" ||
                          site.powerPageSumStatus === "validating"
                        }
                        onClick={() => {
                          handleShow();
                        }}
                        variant="success"
                        size="sm"
                      >
                        {site.powerPageSumStatus === "never"
                          ? "Generate"
                          : "Calculate"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
      {site && site.powerPageSumStatus === "never" && (
        <Alert className="mt-3" key="info" variant="info">
          Press "Generate" button to generate the first power page data.
        </Alert>
      )}
      <Table className="mt-4" striped bordered>
        <thead>
          <tr>
            <th>Power Pages ({pages.length})</th>
            <th className="text-center">Score</th>
            <th className="text-center">Imps</th>
            <th className="text-center">Clicks</th>
            <th className="text-center">CTR %</th>
            <th className="text-center">GSC</th>
            {/* <th># of Keywords</th> */}
          </tr>
        </thead>
        <tbody>
          {pages.map((page) => (
            <tr key={page.id}>
              <td>
                {/* <Link
                  to={
                    "/power-pages/domain/" +
                    domainId +
                    "/page/" +
                    page.id +
                    "/subs"
                  }
                >
                  {" "}
                  {page.page}{" "}
                </Link> */}
                <label htmlFor="" className="text-primary">
                  {page.page}
                </label>
              </td>
              {/* <td>{page.keywords}</td> */}
              <td className="text-center text-danger">{page.score}</td>
              <td className="text-center">{page.impressions}</td>
              <td className="text-center">{page.clicks}</td>
              <td className="text-center">{page.ctr}</td>
              <td className="text-center">
                <Button
                  onClick={() => {
                    window.open(
                      "https://search.google.com/search-console/performance/search-analytics?resource_id=" +
                        encodeURI(site.siteUrl) +
                        "&breakdown=page&page=!" +
                        encodeURI(page.page),
                      "_blank"
                    );
                  }}
                  size="sm"
                  variant="outline-secondary"
                  className="ms-1"
                >
                  <FontAwesomeIcon icon={faShareFromSquare} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="small font-italic text-muted">
        Note: To calculate table data, please go back to domains page.
      </div>
    </div>
  );
};

export default PowerPages;
