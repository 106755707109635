import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import Meta from "../../components/Meta";
import { db } from "../../api/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const SweetSpotKeywords = () => {
  const pageTitle = "SS Keywords";
  const [pageDescription, setPageDescription] = useState("");

  const params = useParams();
  const domainId = params.did ? params.did.toString() : "";
  const pageId = params.pid ? params.pid.toString() : "";

  const init: any[] = [];
  const [keywords, setKeywords] = useState(init);

  const navigate = useNavigate();

  useEffect(() => {
    getPage();
    const doDocRef = doc(
      db,
      "site_monitor/" + domainId + "/page_sweetspot/" + pageId + "/queries",
      "keys"
    );
    onSnapshot(doDocRef, (doc) => {
      const keywordList = (doc.data() as any).keywords as any[];
      setKeywords(
        keywordList.sort(function (a, b) {
          return b.i - a.i;
        })
      );
    });
  }, []);

  const getPage = async () => {
    const p = await getDoc(
      doc(db, "site_monitor/" + domainId + "/page_sweetspot", pageId)
    );
    setPageDescription(p.data()?.page);
  };

  return (
    <div>
      <Meta title={pageTitle} />
      <Header head={pageTitle} description={pageDescription} />
      <div className="row mt-3 mb-3">
        <div className="col">
          <Button
            onClick={() => {
              navigate("/sweet-spot/domain/" + domainId + "/pages");
            }}
            variant="primary"
            size="sm"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
            Pages
          </Button>
        </div>
      </div>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Keywords</th>
            <th>Position</th>
            <th>Imps</th>
            <th>Clicks</th>
            <th>CTR %</th>
          </tr>
        </thead>
        <tbody>
          {keywords.map((key: any, index: number) => {
            return (
              <tr key={key.k}>
                <td>{key.k}</td>
                <td className="text-center text-danger">{key.p}</td>
                <td className="text-center">{key.i}</td>
                <td className="text-center">{key.c}</td>
                <td className="text-center">{key.ctr}%</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SweetSpotKeywords;
