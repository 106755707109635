import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Header from "../components/Header";
import Meta from "../components/Meta";
import parse from "html-react-parser";
import axios from "axios";
import { toast } from "react-toastify";

const HeaderTagConverter = () => {
  const pageTitle = "Header Tag Converter";
  const pageDescription = "Convert Header tag into table of content";

  const [oriHTML, setOriHTML] = useState("");
  const [outputHTML, setOutputHTML] = useState("");
  const [isConverting, setIsConverting] = useState(false);
  const [h1Checked, setH1Checked] = useState(true);
  const [h2Checked, setH2Checked] = useState(true);
  const [h3Checked, setH3Checked] = useState(true);
  const [h4Checked, setH4Checked] = useState(true);
  const [h5Checked, setH5Checked] = useState(true);
  const [h6Checked, setH6Checked] = useState(true);

  const handleConvert = (event: any) => {
    event.preventDefault();
    setIsConverting(true);
    const params = {
      html: oriHTML,
      h1: h1Checked ? 1 : 0,
      h2: h2Checked ? 1 : 0,
      h3: h3Checked ? 1 : 0,
      h4: h4Checked ? 1 : 0,
      h5: h5Checked ? 1 : 0,
      h6: h6Checked ? 1 : 0,
    };

    axios({
      method: "post",
      url: process.env.REACT_APP_BACKEND_API_PATH + "tools/headerTagConvert",
      data: params,
      headers: { "Content-Type": "application/json" },
    })
      .then((response: any) => {
        setOutputHTML(response.data.output);
        setIsConverting(false);
      })
      .catch((e) => {
        setIsConverting(false);
      });
  };

  const oriHTMLHandler = (event: any) => {
    setOriHTML(event.target.value);
  };

  const h1CheckedHandler = (event: any) => {
    setH1Checked(event.currentTarget.checked);
  };

  const h2CheckedHandler = (event: any) => {
    setH2Checked(event.currentTarget.checked);
  };

  const h3CheckedHandler = (event: any) => {
    setH3Checked(event.currentTarget.checked);
  };

  const h4CheckedHandler = (event: any) => {
    setH4Checked(event.currentTarget.checked);
  };

  const h5CheckedHandler = (event: any) => {
    setH5Checked(event.currentTarget.checked);
  };

  const h6CheckedHandler = (event: any) => {
    setH6Checked(event.currentTarget.checked);
  };

  const outputHTMLHandler = (event: any) => {
    setOutputHTML(event.target.value);
  };

  const handleCopy = (event: any) => {
    event.preventDefault();
    // const val = this.form.value.converted;

    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = outputHTML;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);

    toast.success("Copied!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div>
      <Meta title={pageTitle} />
      <Header head={pageTitle} description={pageDescription} />

      <Form>
        <h4 className="mb-2 font-weight-bold text-dark">Filter</h4>
        <div className="row mb-2">
          <Form.Group
            className="mb-3 col-2 col-lg-1"
            controlId="formBasicCheckbox"
          >
            <Form.Check
              type="checkbox"
              checked={h1Checked}
              onChange={h1CheckedHandler}
              label="H1"
            />
          </Form.Group>
          <Form.Group
            className="mb-3 col-2 col-lg-1"
            controlId="formBasicCheckbox"
          >
            <Form.Check
              type="checkbox"
              checked={h2Checked}
              onChange={h2CheckedHandler}
              label="H2"
            />
          </Form.Group>
          <Form.Group
            className="mb-3 col-2 col-lg-1"
            controlId="formBasicCheckbox"
          >
            <Form.Check
              type="checkbox"
              checked={h3Checked}
              onChange={h3CheckedHandler}
              label="H3"
            />
          </Form.Group>
          <Form.Group
            className="mb-3 col-2 col-lg-1"
            controlId="formBasicCheckbox"
          >
            <Form.Check
              type="checkbox"
              checked={h4Checked}
              onChange={h4CheckedHandler}
              label="H4"
            />
          </Form.Group>
          <Form.Group
            className="mb-3 col-2 col-lg-1"
            controlId="formBasicCheckbox"
          >
            <Form.Check
              type="checkbox"
              checked={h5Checked}
              onChange={h5CheckedHandler}
              label="H5"
            />
          </Form.Group>
          <Form.Group
            className="mb-3 col-2 col-lg-1"
            controlId="formBasicCheckbox"
          >
            <Form.Check
              type="checkbox"
              checked={h6Checked}
              onChange={h6CheckedHandler}
              label="H6"
            />
          </Form.Group>
        </div>
        <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
          <h4>HTML Tag</h4>
          <Form.Control
            as="textarea"
            required
            placeholder="Place your html tag here"
            rows={8}
            value={oriHTML}
            onChange={oriHTMLHandler}
          />
        </Form.Group>
        {!isConverting && (
          <Button onClick={handleConvert} variant="primary" type="button">
            CONVERT
          </Button>
        )}
        {isConverting && (
          <Button className="btn btn-primary" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
            CONVERTING...
          </Button>
        )}
        <Form.Group
          className="mb-2 mt-4"
          controlId="exampleForm.ControlTextarea1"
        >
          <h4>Output</h4>
          <Form.Control
            as="textarea"
            placeholder="Converted output (with table of content) will be display here"
            rows={8}
            value={outputHTML}
            onChange={outputHTMLHandler}
          />
        </Form.Group>
        <Button onClick={handleCopy} variant="success" type="button">
          COPY CLIPBOARD
        </Button>
      </Form>
      <div className="mb-2 mt-4">
        <h4>Preview</h4>
        {outputHTML !== "" && (
          <div className="content p-3 border">{parse(outputHTML)}</div>
        )}
        {outputHTML === "" && (
          <div className="content p-3 border text-secondary">
            Preview only display when output is not empty
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderTagConverter;
