import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { db } from "../api/firebase";

const SiteMonitorFilterModal = (props: any) => {
  const site = props.selectedSite;
  const [keywords, setKeywords] = useState("");
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (props.modalShow !== null) {
      setModalShow(props.modalShow);
    }
  }, [props.modalShow]);

  useEffect(() => {
    if (site) {
      if (site.smFilterKeywords) {
        let k = "";
        for (let i = 0; i < site.smFilterKeywords.length; i++) {
          if (i === site.smFilterKeywords.length - 1) {
            k += site.smFilterKeywords[i];
          } else {
            k += site.smFilterKeywords[i] + "\r\n";
          }
        }
        setKeywords(k);
      } else {
        setKeywords("");
      }
    }
  }, [site]);

  const modalSaveHandler = async () => {
    const keyList = keywords.split(/\r?\n/);
    const saveList = [];
    for (let k of keyList) {
      if (k.replace(/\s/g, "") !== "") {
        saveList.push(k);
      }
    }

    const settDocRef = doc(db, "site_monitor", site.id);
    await updateDoc(settDocRef, { smFilterKeywords: saveList });
    props.ModalSaveHandler();
  };

  const keywordHandler = (event: any) => {
    setKeywords(event.target.value);
  };

  return (
    <Modal
      show={modalShow}
      onHide={props.ModalCloseHandler}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Brand Keywords Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Keyword / Regex</Form.Label>
            <Form.Control
              onChange={keywordHandler}
              as="textarea"
              value={keywords}
              placeholder="Excluding keyword or regex"
              rows={6}
            />
            <Form.Text className="text-muted">
              *Case sensitive. Press the "Enter" key to go the next line and key
              in another keyword / regex.
              <br />
              Regular expression that must not be matched.
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.ModalCloseHandler}>
          Close
        </Button>
        <Button variant="primary" onClick={modalSaveHandler}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SiteMonitorFilterModal;
