import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { Container } from "react-bootstrap";
import HeaderTagConverter from "./app/pages/HeaderTagConverter";
import Layout from "./app/components/Layout";
import NotFound from "./app/pages/NotFound";
import SiteTraffic from "./app/pages/SiteTraffic";
import Login from "./app/pages/Login";
import { useAppDispatch, useAppSelector } from "./app/store/hooks";
import Splash from "./app/components/Splash";
import Auth from "./app/models/Auth";
import { authChecking } from "./app/store/auth-actions";
import { ToastContainer } from "react-toastify";
import { siteAlertCount } from "./app/store/site-alert-actions";
import PowerPageDomains from "./app/pages/power-pages/Domains";
import PowerPages from "./app/pages/power-pages/Pages";
import SweetSpotDomains from "./app/pages/sweet-spots/Domains";
import SweetSpotPages from "./app/pages/sweet-spots/Pages";
import SweetSpotKeywords from "./app/pages/sweet-spots/keywords";
import Domains from "./app/pages/Domains";
import KeywordsTracker from "./app/pages/keywords-tracker/keywords";

function App() {
  const dispatch = useAppDispatch();
  const auth: Auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(authChecking());
  }, [dispatch]);

  useEffect(() => {
    if (auth.isAuth) {
      dispatch(siteAlertCount());
    }
  }, [auth.isAuth, dispatch]);

  return (
    <>
      {auth.isLoading && <Splash />}
      {!auth.isLoading && (
        <Layout>
          <ToastContainer />
          <Container>
            <Routes>
              {auth.isAuth ? (
                <>
                  <Route
                    path="/auth"
                    element={<Navigate to="/sites-traffic" />}
                  />
                  <Route
                    path="/header-tag-converter"
                    element={<HeaderTagConverter />}
                  />
                  <Route path="/sites-traffic" element={<SiteTraffic />} />
                  <Route path="/domains" element={<Domains />} />
                  <Route
                    path="/power-pages/domain/:did/pages"
                    element={<PowerPages />}
                  />
                  <Route
                    path="/sweet-spot/domain/:did/pages"
                    element={<SweetSpotPages />}
                  />
                  <Route
                    path="/sweet-spot/domain/:did/page/:pid/keywords"
                    element={<SweetSpotKeywords />}
                  />
                  <Route
                    path="/keywords-tracker/domain/:did/keywords"
                    element={<KeywordsTracker />}
                  />
                  <Route path="/" element={<Navigate to="/sites-traffic" />} />
                  <Route path="*" element={<NotFound />} />
                </>
              ) : (
                <>
                  <Route path="auth" element={<Login />} />
                  <Route path="*" element={<Navigate to="/auth" />} />
                </>
              )}
            </Routes>
          </Container>
        </Layout>
      )}
    </>
  );
}

export default App;
