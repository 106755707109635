import { signedIn, signedOut, signingIn, error } from "./auth-slice";
import { auth, logInWithEmailAndPassword, logout } from "../api/firebase";
import { User } from "firebase/auth";

export const signin = (email: string, password: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(signingIn());
      await logInWithEmailAndPassword(email, password);
    } catch (err: any) {
      dispatch(error({ errMsg: err.message }));
    }
  };
};

export const signout = () => {
  return (dispatch: any) => {
    try {
      logout();
    } catch (err) {
      console.log(err);
    }
  };
};

export const authChecking = () => {
  return (dispatch: any) => {
    try {
      auth.onAuthStateChanged((user: User | null) => {
        if (user) {
          dispatch(signedIn(user.email));
        } else {
          dispatch(signedOut());
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
};
