import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
};

const siteAlertSlice = createSlice({
  name: "siteAlert",
  initialState: initialState,
  reducers: {
    alertCount(state, action) {
      state.count = action.payload.count;
    },
  },
});

export const { alertCount } = siteAlertSlice.actions;

export default siteAlertSlice.reducer;
