import { Helmet, HelmetProvider } from 'react-helmet-async';

const Meta = ({ title }: any) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </HelmetProvider>
  );
};
export default Meta;
