import { createSlice } from "@reduxjs/toolkit";
import Auth from "../models/Auth";

const initialState: Auth = {
  email: "",
  isLoading: true,
  isAuth: false,
  isSigningIn: false,
  isErr: false,
  errMsg: "",
};

const authSlice = createSlice({
  name: "toast",
  initialState: initialState,
  reducers: {
    signedIn(state, action) {
      state.email = action.payload.email;
      state.isAuth = true;
      state.isLoading = false;
      state.isSigningIn = false;
    },
    signedOut(state) {
      state.email = "";
      state.isAuth = false;
      state.isLoading = false;
      state.isSigningIn = false;
    },
    signingIn(state) {
      state.isSigningIn = true;
      state.isErr = false;
      state.errMsg = "";
    },
    error(state, action) {
      state.isSigningIn = false;
      state.isErr = true;
      state.errMsg = action.payload.errMsg;
    },
  },
});

export const { signedIn, signedOut, signingIn, error } = authSlice.actions;

export default authSlice.reducer;
