import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { db } from "../api/firebase";

const SiteMonitorConfigModal = (props: any) => {
  const _setting = props.setting;

  const [down_alert_percent, setDownAlertPercent] = useState(0);
  const [comparison_days, setComparisonDays] = useState(0);
  const [summarize_freq_days, setSummarizeFreqDays] = useState(0);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (_setting) {
      setDownAlertPercent(_setting.down_alert_percent);
      setComparisonDays(_setting.comparison_days);
      setSummarizeFreqDays(_setting.summarize_freq_days);
    }
  }, [_setting]);

  useEffect(() => {
    if (props.modalShow !== null) {
      setModalShow(props.modalShow);
    }
  }, [props.modalShow]);

  const downAlertPercentHandler = (event: any) => {
    setDownAlertPercent(event.currentTarget.value);
  };

  const comparisonDaysHandler = (event: any) => {
    setComparisonDays(event.currentTarget.value);
  };

  const summarizeFreqDaysHandler = (event: any) => {
    setSummarizeFreqDays(event.currentTarget.value);
  };

  const modalSaveCalculateHandler = async () => {
    const settDocRef = doc(db, "setting", "seo_tools");
    const data = {
      down_alert_percent,
      comparison_days,
      summarize_freq_days,
    };
    await updateDoc(settDocRef, data);
    props.ModalSaveCalculateHandler();
  };

  const modalSaveHandler = async () => {
    const settDocRef = doc(db, "setting", "seo_tools");
    const data = {
      down_alert_percent,
      comparison_days,
      summarize_freq_days,
    };
    await updateDoc(settDocRef, data);

    props.ModalSaveHandler();
  };

  return (
    <Modal
      show={modalShow}
      onHide={props.ModalCloseHandler}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={7}>
              Alert Threshold
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type="number"
                placeholder="alert threshold"
                value={down_alert_percent}
                onChange={downAlertPercentHandler}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={7}>
              Data Day Range
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type="number"
                placeholder="data day range"
                value={comparison_days}
                onChange={comparisonDaysHandler}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={7}>
              Auto Summarize Freq (days)
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type="number"
                placeholder="Auto Summarize Freq"
                value={summarize_freq_days}
                onChange={summarizeFreqDaysHandler}
                disabled
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.ModalCloseHandler}>
          Close
        </Button>
        <Button variant="warning" onClick={modalSaveCalculateHandler}>
          Save & Calculate
        </Button>
        <Button variant="primary" onClick={modalSaveHandler}>
          Save Only
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SiteMonitorConfigModal;
