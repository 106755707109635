import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Meta from "../components/Meta";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../api/firebase";
import {
  BsFillTrophyFill,
  BsPinAngleFill,
  BsTextIndentLeft,
} from "react-icons/bs";

const Domains = () => {
  const pageTitle = "Domains / Site URLs";
  const pageDescription = "Domains that currently managed by Growth Pro";

  const init: any[] = [];
  const [domains, setDomains] = useState(init);
  const navigate = useNavigate();

  useEffect(() => {
    const q = query(
      collection(db, "site_monitor"),
      where("status", "==", "active")
    );
    onSnapshot(q, (querySnapshot) => {
      const objList: any[] = querySnapshot.docs.map((doc: any) => {
        const obj = doc.data() as any;
        obj.id = doc.id;
        return obj;
      });

      setDomains(objList);
    });
  }, []);

  const navigateToSweetSpot = (domainId: string) => {
    navigate("/sweet-spot/domain/" + domainId + "/pages");
  };

  const navigateToPowerPage = (domainId: string) => {
    navigate("/power-pages/domain/" + domainId + "/pages");
  };

  const navigateToKeywordsTracker = (domainId: string) => {
    navigate("/keywords-tracker/domain/" + domainId + "/keywords");
  };

  return (
    <div>
      <Meta title={pageTitle} />
      <Header head={pageTitle} description={pageDescription} />
      <Table striped bordered>
        <thead>
          <tr>
            <th className="th-sm col-6 col-md-8">Site URLs</th>
            <th className="text-start col-6 col-md-4">
              Power Pages / Sweet Spot / Keyword Tracker
            </th>
          </tr>
        </thead>
        <tbody>
          {domains.map((site) => (
            <tr key={site.id}>
              <td>
                <div>{site.siteUrl}</div>
              </td>
              <td className="text-start">
                <Button
                  className="me-2 mb-1"
                  onClick={() => navigateToPowerPage(site.id)}
                  variant={
                    site.powerPageSumStatus &&
                    site.powerPageSumStatus !== "never"
                      ? "danger"
                      : "light"
                  }
                  size="sm"
                >
                  <BsFillTrophyFill /> PP
                </Button>
                <Button
                  className="me-2 mb-1"
                  onClick={() => navigateToSweetSpot(site.id)}
                  variant={
                    site.ssSumStatus && site.ssSumStatus !== "never"
                      ? "success"
                      : "light"
                  }
                  size="sm"
                >
                  <BsTextIndentLeft /> SS
                </Button>
                <Button
                  className="me-2 mb-1"
                  onClick={() => {
                    navigateToKeywordsTracker(site.id);
                  }}
                  variant={
                    site.ktSumStatus && site.ktSumStatus !== "never"
                      ? "primary"
                      : "light"
                  }
                  size="sm"
                >
                  <BsPinAngleFill /> KT
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Domains;
