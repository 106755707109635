import { ChangeEvent, useEffect, useState } from "react";
import { db } from "../api/firebase";
import Header from "../components/Header";
import Meta from "../components/Meta";
import {
  collection,
  query,
  getDocs,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
// import { useAppSelector, useAppDispatch } from "../store/hooks";
import { Badge, Button, Spinner, Table } from "react-bootstrap";
import Site from "../models/Site";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import styles from "./SiteTraffic.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faSquareRootVariable,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SiteMonitorConfigModal from "./SiteMonitorConfig.modal";
import { useAppSelector } from "../store/hooks";
import SiteMonitorFilterModal from "./SiteMonitorFilter.modal";

interface Setting {
  comparison_days: number;
  down_alert_percent: number;
  last_date_end: string;
  last_date_start: string;
  last_update_at: string;
  prev_last_date_end: string;
  prev_last_date_start: string;
  summarize_freq_days: number;
}

const SiteTraffic = () => {
  const pageTitle = "Sites Monitor";
  const pageDescription = "Data extracted from Google Search console API";
  const siteAlert = useAppSelector((state) => state.siteAlert);
  const init: Site[] = [];
  const [trackingList, setTrackingList] = useState(init);
  const [untrackedList, setUntrackedList] = useState(init);
  const [inactiveList, setInactiveList] = useState(init);
  const initSetting: Setting | any = null;
  const [setting, setSetting] = useState(initSetting);
  const [seletectedSite, setSeletectedSite] = useState<Site | undefined>(
    undefined
  );

  let firstLoad = true;
  let dataSubscribed = false;
  // const [isLoading, setIsLoading] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);

  const [modalConfigShow, setModalConfigShow] = useState(false);
  const ModalConfigCloseHandler = () => setModalConfigShow(false);
  const ModalConfigShowHandler = () => setModalConfigShow(true);
  const ModalConfigSaveHandler = async () => {
    setModalConfigShow(false);
    await getSetting();
    toast.success("Configuration Saved", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const [modalFilterShow, setModalFilterShow] = useState(false);
  const ModalFilterCloseHandler = () => setModalFilterShow(false);
  const ModalFilterShowHandler = (siteId: string) => {
    let s = trackingList.find((e) => e.id === siteId);
    if (!s) {
      s = untrackedList.find((e) => e.id === siteId);
    }

    if (s) {
      setSeletectedSite(s);
      setModalFilterShow(true);
    }
  };
  const ModalFilterSaveHandler = async () => {
    loadSites();

    setModalFilterShow(false);
  };

  const ModalConfigSaveCalculateHandler = () => {
    setModalConfigShow(false);

    toast.success("Configuration Saved. Waiting for re-calculating.", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    recalculate();
  };

  useEffect(() => {
    getSetting();
  }, []);

  async function getSetting() {
    const settDocRef = doc(db, "setting", "seo_tools");
    onSnapshot(settDocRef, (doc) => {
      if (doc.exists()) {
        const setting = doc.data() as Setting;
        setting.prev_last_date_start = dateSimplify(
          setting.prev_last_date_start
        );
        setting.prev_last_date_end = dateSimplify(setting.prev_last_date_end);
        setting.last_date_start = dateSimplify(setting.last_date_start);
        setting.last_date_end = dateSimplify(setting.last_date_end);
        setSetting(setting);

        loadSites();
      } else {
        alert("setting not exist.");
      }
    });
  }

  async function loadSites() {
    const sites = await getDocs(query(collection(db, "site_monitor")));

    const objList: Site[] = sites.docs.map((doc: any) => {
      const obj = doc.data() as Site;
      obj.id = doc.id;
      if (obj.domainProperty) {
        obj.siteUrl = obj.siteUrl.replace(/sc-domain:/g, "");
      }

      return obj;
    });

    setTrackingList(objList.filter((ele) => ele.alertToggle));
    setUntrackedList(objList.filter((ele) => !ele.alertToggle));
    setInactiveList(objList.filter((ele) => ele.status === "inactive"));
  }

  const dateSimplify = (dateString: string) => {
    const split = dateString.split("-");

    return split[2] + "/" + split[1];
  };

  const alertOnChangeHandler = async (
    e: ChangeEvent<{ checked: boolean; value: string }>
  ) => {
    await updateDoc(doc(db, "site_monitor", e?.currentTarget?.value), {
      alertToggle: e?.currentTarget?.checked,
    });
  };

  const onCalculateHandler = () => {
    recalculate();
  };

  const recalculate = async () => {
    setIsCalculating(true);
    const r = await axios.get(
      process.env.REACT_APP_BACKEND_API_PATH + "gsc/calculateGSCData"
    );
    if (r.status === 200) {
      await getSetting();
      toast.success("Success. Wait 1-2 minutes and refresh this page", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error on calculation.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setIsCalculating(false);
  };

  return (
    <div>
      <Meta title={pageTitle} />
      <Header head={pageTitle} description={pageDescription} />
      <SiteMonitorConfigModal
        modalShow={modalConfigShow}
        setting={setting}
        ModalSaveHandler={ModalConfigSaveHandler}
        ModalCloseHandler={ModalConfigCloseHandler}
        ModalSaveCalculateHandler={ModalConfigSaveCalculateHandler}
      />
      <SiteMonitorFilterModal
        modalShow={modalFilterShow}
        selectedSite={seletectedSite}
        ModalSaveHandler={ModalFilterSaveHandler}
        ModalCloseHandler={ModalFilterCloseHandler}
      />

      {setting && (
        <div className="row mt-3 mb-3">
          <div className="col text-secondary">
            Last update at {setting.last_update_at} GMT
          </div>
          <div className="col text-end float-end">
            {!isCalculating && (
              <OverlayTrigger
                key={"trigger-tooltip-1"}
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-summarize`}>Recalculate data</Tooltip>
                }
              >
                <Button
                  variant="outline-primary"
                  onClick={onCalculateHandler}
                  className="ms-1"
                >
                  <FontAwesomeIcon icon={faSquareRootVariable} />
                </Button>
              </OverlayTrigger>
            )}
            {isCalculating && (
              <Button variant="primary" className="ms-1" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-1"
                />
                Calculating...
              </Button>
            )}
            <OverlayTrigger
              key={"trigger-tooltip-2"}
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-config`}>Configuration</Tooltip>}
            >
              <Button
                variant="outline-primary"
                onClick={ModalConfigShowHandler}
                className="ms-1"
              >
                <FontAwesomeIcon icon={faGear} />
              </Button>
            </OverlayTrigger>
          </div>
        </div>
      )}
      <Tabs defaultActiveKey="tracking" id="site-track-tab" className="mb-3">
        <Tab
          eventKey="tracking"
          title={
            <>
              Watch List
              {siteAlert.count > 0 && (
                <Badge className="ms-1" pill bg="danger">
                  {siteAlert.count}
                </Badge>
              )}
            </>
          }
        >
          {setting && (
            <Table striped bordered>
              <thead>
                <tr>
                  <th rowSpan={2}>Site URLs</th>
                  <th className="text-center small">
                    {setting.prev_last_date_start} -{" "}
                    {setting.prev_last_date_end}
                  </th>
                  <th className="text-center small">
                    {setting.last_date_start} - {setting.last_date_end}
                  </th>
                  <th className="text-center small">
                    {setting.prev_last_date_start} -{" "}
                    {setting.prev_last_date_end}
                  </th>
                  <th className="text-center small">
                    {setting.last_date_start} - {setting.last_date_end}
                  </th>
                  <th rowSpan={2}>Alert ON/OFF</th>
                  <th rowSpan={2}>Brand Keywords Filter</th>
                </tr>
                <tr>
                  <th colSpan={2} className="text-center">
                    Clicks
                  </th>
                  <th colSpan={2} className="text-center">
                    Impressions
                  </th>
                </tr>
              </thead>
              <tbody>
                {trackingList.length === 0 && (
                  <tr key="inactive-nodata">
                    <td colSpan={6} className="text-center">
                      No data
                    </td>
                  </tr>
                )}
                {trackingList.map((site) => (
                  <tr
                    className={site.isWarning ? styles.rowDanger : ""}
                    key={site.id}
                  >
                    <td>
                      <a
                        target="_blank"
                        href={
                          "https://search.google.com/search-console?resource_id=" +
                          site.siteUrl
                        }
                        rel="noreferrer"
                      >
                        <label htmlFor="" className={styles.siteFont}>
                          {site.siteUrl}
                        </label>
                        {site.domainProperty && (
                          <Badge className="ms-1 small" pill bg="secondary">
                            DP
                          </Badge>
                        )}
                      </a>
                    </td>
                    <td className="text-center">{site.prevTotalClicks}</td>
                    <td
                      className={`text-center ${
                        site.alertTotalClicks ? "text-danger fw-bold" : ""
                      } ${site.growTotalClicks ? "text-success fw-bold" : ""}`}
                    >
                      {site.curTotalClicks} ({site.changeTotalClicks}%)
                    </td>
                    <td className="text-center">{site.prevTotalImps}</td>
                    <td
                      className={`text-center ${
                        site.alertTotalImps ? "text-danger fw-bold" : ""
                      } ${site.growTotalImps ? "text-success fw-bold" : ""}`}
                    >
                      {site.curTotalImps} ({site.changeTotalImps}%)
                    </td>
                    <td>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={"alert-" + site.id}
                          defaultChecked={site.alertToggle}
                          value={site.id}
                          onChange={alertOnChangeHandler}
                        />
                        {/* <label className="form-check-label">Alert</label> */}
                      </div>
                    </td>
                    <td>
                      <Button
                        variant="outline-primary"
                        onClick={() => ModalFilterShowHandler(site.id)}
                        className="ms-1"
                      >
                        <FontAwesomeIcon icon={faFilter} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Tab>
        <Tab eventKey="untracked" title="Untracked">
          {setting && (
            <Table striped bordered>
              <thead>
                <tr>
                  <th rowSpan={2}>Site URLs</th>
                  <th className="text-center small">
                    {setting.prev_last_date_start} -{" "}
                    {setting.prev_last_date_end}
                  </th>
                  <th className="text-center small">
                    {setting.last_date_start} - {setting.last_date_end}
                  </th>
                  <th className="text-center small">
                    {setting.prev_last_date_start} -{" "}
                    {setting.prev_last_date_end}
                  </th>
                  <th className="text-center small">
                    {setting.last_date_start} - {setting.last_date_end}
                  </th>
                  <th rowSpan={2}>Alert ON/OFF</th>
                </tr>
                <tr>
                  <th colSpan={2} className="text-center">
                    Clicks
                  </th>
                  <th colSpan={2} className="text-center">
                    Impressions
                  </th>
                </tr>
              </thead>
              <tbody>
                {untrackedList.length === 0 && (
                  <tr key="inactive-nodata">
                    <td colSpan={6} className="text-center">
                      No data
                    </td>
                  </tr>
                )}
                {untrackedList.map((site) => (
                  <tr key={site.id}>
                    <td>
                      <a
                        target="_blank"
                        href={
                          "https://search.google.com/search-console?resource_id=" +
                          site.siteUrl
                        }
                        rel="noreferrer"
                      >
                        <label htmlFor="" className={styles.siteFont}>
                          {site.siteUrl}
                        </label>
                        {site.domainProperty && (
                          <Badge className="ms-1" pill bg="secondary">
                            DP
                          </Badge>
                        )}
                      </a>
                    </td>
                    <td className="text-center">{site.prevTotalClicks}</td>
                    <td
                      className={
                        site.alertTotalClicks
                          ? "text-center text-danger fw-bold"
                          : "text-center"
                      }
                    >
                      {site.curTotalClicks} ({site.changeTotalClicks}%)
                    </td>
                    <td className="text-center">{site.prevTotalImps}</td>
                    <td
                      className={
                        site.alertTotalImps
                          ? "text-center text-danger fw-bold"
                          : "text-center"
                      }
                    >
                      {site.curTotalImps} ({site.changeTotalImps}%)
                    </td>
                    <td>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={"alert-" + site.id}
                          defaultChecked={site.alertToggle}
                          value={site.id}
                          onChange={alertOnChangeHandler}
                        />
                        {/* <label className="form-check-label">Alert</label> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Tab>
        <Tab eventKey="inactive" title="Inactive">
          {setting && (
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Site URLs</th>
                  <th className="text-center">Clicks (Last)</th>
                  <th className="text-center">Impressions (Last)</th>
                  <th rowSpan={2}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {inactiveList.length === 0 && (
                  <tr key="inactive-nodata">
                    <td colSpan={4} className="text-center">
                      No data
                    </td>
                  </tr>
                )}
                {inactiveList.map((site) => (
                  <tr key={site.id}>
                    <td>
                      <a href="#" rel="noreferrer">
                        <label htmlFor="" className={styles.siteFont}>
                          {site.siteUrl}
                        </label>
                        {site.domainProperty && (
                          <Badge className="ms-1" pill bg="secondary">
                            DP
                          </Badge>
                        )}
                      </a>
                    </td>
                    <td className="text-center">{site.curTotalClicks}</td>
                    <td className="text-center">{site.curTotalImps}</td>
                    <td>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={"alert-" + site.id}
                          defaultChecked={site.alertToggle}
                          disabled
                        />
                        <label className="form-check-label">Alert</label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Tab>
      </Tabs>

      <div className="small font-italic text-muted">
        Note: DP = Domain Property
      </div>
      <div className="small font-italic text-muted">
        Note: Date and data are based on PST
      </div>
      <div className="small font-italic text-muted">
        Note: To track a new domain/url, add
        growth-service-account@growth-pro.iam.gserviceaccount.com into GSC role
      </div>
    </div>
  );
};

export default SiteTraffic;
