import { Link, useNavigate, useLocation } from "react-router-dom";
import { Nav, Container, Badge } from "react-bootstrap";
import { useAppSelector } from "../store/hooks";
import Auth from "../models/Auth";
import { useAppDispatch } from "../store/hooks";
import { signout } from "../store/auth-actions";
import { useEffect, useState } from "react";

const Menu = () => {
  const dispatch = useAppDispatch();
  const auth: Auth = useAppSelector((state) => state.auth);
  const siteAlert = useAppSelector((state) => state.siteAlert);
  const navigate = useNavigate();
  const location = useLocation();

  const [activeNav, setActiveNav] = useState("");

  useEffect(() => {
    setActiveNav(location.pathname);
  }, [location.pathname]);

  const eventKeyHandler = (selectedKey: any) => {
    if (selectedKey === "/sites-traffic") {
      navigate("/sites-traffic");
    }
    if (selectedKey === "/header-tag-converter") {
      navigate("/header-tag-converter");
    }
    if (selectedKey === "/domains") {
      navigate("/domains");
    }
    if (selectedKey === "logout") {
      dispatch(signout());
    }
  };

  return (
    <Container>
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        <Link
          to="/"
          className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none font-weight-bold"
          style={{ fontSize: "1.2em", fontWeight: "bold" }}
        >
          SEO Tools
        </Link>
        <Nav activeKey={activeNav} onSelect={eventKeyHandler}>
          {auth.isAuth && (
            <>
              <Nav.Item>
                <Nav.Link eventKey="/sites-traffic">
                  Sites Monitor
                  {siteAlert.count > 0 && (
                    <Badge className="ms-1" pill bg="danger">
                      {siteAlert.count}
                    </Badge>
                  )}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="/header-tag-converter">
                  Header Tag Converter
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="/domains">Domains</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="logout">Logout</Nav.Link>
              </Nav.Item>
            </>
          )}
        </Nav>
      </header>
    </Container>
  );
};

export default Menu;
