import { Container } from "react-bootstrap";
const Header = ({ head, description }: any) => {
  return (
    <Container>
      <div className="starter-template text-center mt-1 mb-4">
        <h1>{head}</h1>
        <p className="lead">{description}</p>
      </div>
    </Container>
  );
};
export default Header;
