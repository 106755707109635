import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { signin } from "../store/auth-actions";
import { useState } from "react";
import Auth from "../models/Auth";

const Login = () => {
  const dispatch = useAppDispatch();
  const auth: Auth = useAppSelector((state) => state.auth);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signinHandler = () => {
    dispatch(signin(email, password));
  };

  const emailHandler = (event: any) => {
    setEmail(event.currentTarget.value);
  };

  const passwordHandler = (event: any) => {
    setPassword(event.currentTarget.value);
  };

  return (
    <Container fluid="md" className="mt-5 mb-5">
      <Row className="justify-content-md-center">
        <Col xs md="8" lg="6" xl="4">
          {auth.isErr && (
            <Alert key="err-msg" variant="danger">
              {auth.errMsg}
            </Alert>
          )}

          <div className="card">
            <div className="card-header">Authentication</div>
            <div className="card-body">
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={emailHandler}
                    placeholder="Enter email"
                  />
                  {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={passwordHandler}
                    placeholder="Password"
                  />
                </Form.Group>
                <div className="d-grid gap-2">
                  <Button
                    variant="primary"
                    className="mt-2"
                    size="lg"
                    type="button"
                    onClick={signinHandler}
                    disabled={auth.isSigningIn}
                  >
                    {auth.isSigningIn && (
                      <div>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        Loading...
                      </div>
                    )}
                    {!auth.isSigningIn && <div>Login</div>}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
