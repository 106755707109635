const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <footer className="text-center text-capitalize mt-5">
        copyright Growth Pro &copy; {year}. Ver {process.env.REACT_APP_VERSION}
      </footer>
    </>
  );
};
export default Footer;
