import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  addDoc,
  query,
  deleteDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import axios from "axios";
import { db } from "../../api/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faAdd } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import classNames from "classnames";
import {
  BsArrowDownShort,
  BsArrowUpShort,
  BsDash,
  BsTrashFill,
} from "react-icons/bs";

const KeywordsTracker = () => {
  const pageTitle = "Keywords Tracker";
  const [pageDescription, setPageDescription] = useState("");

  const params = useParams();
  const domainId = params.did ? params.did.toString() : "";

  const initDom: any = null;
  const [site, setSite] = useState(initDom);

  const init: any[] = [];
  const [keywords, setKeywords] = useState(init);
  const [monthlist, setMonthlist] = useState(init);
  const [selectedKeywordToDelete, setSelectedKeywordToDelete] = useState({
    keyword: "",
    id: "",
  });
  const countriesInit: any[] = [
    { name: "Australia", code: "AUS", isSelected: false },
    { name: "Hong Kong", code: "HKG", isSelected: false },
    { name: "Indonesia", code: "IDN", isSelected: false },
    { name: "Malaysia", code: "MYS", isSelected: false },
    { name: "Philippines", code: "PHL", isSelected: false },
    { name: "Singapore", code: "SGP", isSelected: false },
    { name: "Thailand", code: "THA", isSelected: false },
    { name: "United States", code: "USA", isSelected: false },
    { name: "Vietnam", code: "VNM", isSelected: false },
  ];

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSave = () => {
    setShow(false);
    onKeywordSave();
  };

  const [deleteModalshow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  const handleDelete = () => {
    setDeleteModalShow(false);
    onKeywordDelete(selectedKeywordToDelete.id);
  };

  const navigate = useNavigate();

  const [includeThisMonth, setIncludeThisMonth] = useState(false);
  const [globalSelected, setGlobalSelected] = useState(true);
  const [newKeyword, setNewKeyword] = useState("");
  const [countries, setCountries] = useState(countriesInit);

  const globalSelectHandler = (event: any) => {
    setGlobalSelected(event.currentTarget.checked);
  };

  const countrySelectHandler = (event: any, code: string) => {
    const inx = countries.findIndex((e) => e.code === code);

    countries[inx].isSelected = event.currentTarget.checked;
    setCountries([...countries]);

    // if (event.currentTarget.checked === true) {
    //   console.log("true");
    //   if (inx < 0) {
    //     console.log("push");
    //     locations.push(code);
    //   }
    // } else {
    //   if (inx >= 0) {
    //     locations.splice(inx, 1);
    //   }
    // }
    // console.log(locations);
    //setUSASelected(event.currentTarget.checked);
  };

  const keywordHandler = (event: any) => {
    setNewKeyword(event.target.value);
  };

  useEffect(() => {
    loadDomain();
  }, []);

  const loadDomain = async () => {
    const doDocRef = doc(db, "site_monitor", domainId);
    onSnapshot(doDocRef, (doc) => {
      const site = doc.data() as any;
      site.id = domainId;
      if (site.ktSumMonthList) {
        const newMonthList: any[] = [];
        site.ktSumMonthList.forEach((e: any) => {
          newMonthList.push(e.start);
        });
        setMonthlist(newMonthList.reverse());
      }
      setSite(site);
      setPageDescription(site.siteUrl);
      loadKeywords();
    });
  };

  const loadKeywords = async () => {
    const q = await getDocs(
      query(collection(db, "site_monitor/" + domainId + "/keywords_tracker"))
    );
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any;
      obj.id = doc.id;

      return obj;
    });
    setKeywords(
      objList.sort((a, b) => {
        return a.keyword.localeCompare(b.keyword, "en", {
          sensitivity: "base",
        });
      })
    );
  };

  const onKeywordDelete = async (keyId: string) => {
    await deleteDoc(
      doc(db, "site_monitor/" + domainId + "/keywords_tracker/" + keyId)
    );
    loadKeywords();
  };

  const onKeywordSave = async () => {
    const keyList = newKeyword.split(/\r?\n/);

    if (!globalSelected) {
      let locations = countries.filter((e) => e.isSelected === true);

      for (let k of keyList) {
        if (k.replace(/\s/g, "") !== "") {
          locations.forEach((l) => {
            addDoc(
              collection(db, "site_monitor/" + domainId + "/keywords_tracker"),
              {
                keyword: k,
                slot1: 0,
                slot2: 0,
                slot3: 0,
                slot4: 0,
                slot5: 0,
                slot6: 0,
                slot7: 0,
                slot8: 0,
                slot9: 0,
                slot10: 0,
                slot11: 0,
                slot12: 0,
                location: l.code,
              }
            );
          });
        }
      }
    } else {
      for (let k of keyList) {
        if (k.replace(/\s/g, "") !== "") {
          await addDoc(
            collection(db, "site_monitor/" + domainId + "/keywords_tracker"),
            {
              keyword: k,
              slot1: 0,
              slot2: 0,
              slot3: 0,
              slot4: 0,
              slot5: 0,
              slot6: 0,
              slot7: 0,
              slot8: 0,
              slot9: 0,
              slot10: 0,
              slot11: 0,
              slot12: 0,
              location: "",
            }
          );
        }
      }
    }

    loadKeywords();
  };

  const onCalculateHandler = async () => {
    // await updateDoc(doc(db, "site_monitor", site.id), {
    //   powerPageSumStatus: "validating",
    //   powerPageSumAt: "Validating...",
    // });

    const r = await axios.post(
      process.env.REACT_APP_BACKEND_API_PATH + "gsc/calculateKeywordTracker",
      { domainid: site.id, includeCurrentMonth: includeThisMonth }
    );
    if (r.status === 200) {
      // toast.success("This might take up to 5 - 30 minutes.", {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    } else {
      // await updateDoc(doc(db, "site_monitor", site.id), {
      //   powerPageSumStatus: "completed",
      //   powerPageSumAt: "Error",
      // });
    }

    // recalculate();
  };

  const getCountryName = (code: string) => {
    if (code) {
      return countries.find((e) => e.code === code).name;
    } else {
      return "Global";
    }
  };

  return (
    <div>
      <Meta title={pageTitle} />
      {site && (
        <Modal show={deleteModalshow} onHide={handleDeleteModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure to delete "{selectedKeywordToDelete.keyword}"?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteModalClose}>
              Close
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {site && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Keyword</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Keyword</Form.Label>
                <Form.Control
                  onChange={keywordHandler}
                  as="textarea"
                  value={newKeyword}
                  placeholder="Enter your keyword"
                  rows={6}
                />
                <Form.Text className="text-muted">
                  *Case sensitive. Press the "Enter" key to go the next line and
                  key in another keyword.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  onChange={globalSelectHandler}
                  type="checkbox"
                  label="Global"
                  checked={globalSelected}
                />
                {countries.map((country) => (
                  <Form.Check
                    key={country.code}
                    disabled={globalSelected}
                    checked={country.isSelected}
                    type="checkbox"
                    onChange={(event) =>
                      countrySelectHandler(event, country.code)
                    }
                    label={country.name}
                  />
                ))}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {site && (
        <Card className="mb-5">
          <Card.Body>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 col-xl-7">
                <div>You are viewing</div>
                <div>
                  <h4 className="pt-3">{site.siteUrl}</h4>
                </div>
                <div>Keywords Tracker</div>
                <div className="mt-3">
                  <Button
                    className="me-2"
                    onClick={() => {
                      navigate("/domains");
                    }}
                    variant="dark"
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
                    Domains
                  </Button>
                  <Button
                    onClick={() => {
                      handleShow();
                    }}
                    variant="primary"
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faAdd} className="me-1" />
                    New Keyword
                  </Button>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 col-xl-3 text-secondary text-center d-flex align-items-center">
                <div>
                  <div>
                    {site.ktSumStatus === "never" && (
                      <>
                        <div
                          className="text-info"
                          style={{ fontSize: "1.2em", fontWeight: "bold" }}
                        >
                          Never Calculate
                        </div>
                        <div>Add new keyword before calculate</div>
                      </>
                    )}
                    {site.ktSumStatus === "error" && (
                      <>
                        <span
                          className="text-danger"
                          style={{ fontSize: "1.2em", fontWeight: "bold" }}
                        >
                          Error!
                        </span>
                      </>
                    )}
                    {site.ktSumStatus === "completed" && (
                      <>
                        <span
                          className="text-success"
                          style={{ fontSize: "1.2em", fontWeight: "bold" }}
                        >
                          Completed
                        </span>
                      </>
                    )}
                    {site.ktSumStatus === "pending" && (
                      <>
                        <Spinner
                          className="me-1"
                          variant="warning"
                          animation="grow"
                          size="sm"
                        />{" "}
                        <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                          Calculating
                        </span>
                      </>
                    )}
                    {site.ktSumStatus === "validating" && (
                      <>
                        <Spinner
                          className="me-1"
                          variant="info"
                          animation="grow"
                          size="sm"
                        />{" "}
                        <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                          Validating
                        </span>
                      </>
                    )}
                  </div>
                  {site.ktSumStatus !== "never" && (
                    <div className="pt-2" style={{ fontSize: "0.8em" }}>
                      Last updated
                    </div>
                  )}
                  <div style={{ fontSize: "0.8em", fontWeight: "bold" }}>
                    {site.ktSumAt ? site.ktSumAt : "-"}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 col-xl-2 d-flex align-items-center">
                <div>
                  <div style={{ width: "100%" }} className="mb-2">
                    <Form.Select disabled={true} size="sm">
                      <option value="month">By Month</option>
                    </Form.Select>
                  </div>
                  <div>
                    <Form.Check
                      defaultChecked={includeThisMonth}
                      onChange={(e) => {
                        setIncludeThisMonth(e?.currentTarget?.checked);
                      }}
                      type="checkbox"
                      label="Include current month"
                    />
                  </div>
                  <div style={{ width: "100%" }} className="mb-2">
                    <div className="d-grid gap-2">
                      <Button
                        disabled={
                          site.ktSumStatus === "pending" ||
                          site.ktSumStatus === "validating"
                        }
                        onClick={() => {
                          onCalculateHandler();
                        }}
                        variant="success"
                        size="sm"
                      >
                        Calculate
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
      {site && keywords.length === 0 && (
        <Alert className="mt-3" key="info" variant="info">
          No keyword added for tracking.
        </Alert>
      )}
      {site && keywords.length > 0 && (
        <Table bordered>
          <thead>
            <tr>
              <th>Keyword</th>
              <th className="text-center">Location</th>
              {monthlist.map((month: any) => {
                return (
                  <th key={month} className="text-center">
                    <Moment format="MMM YY">{month}</Moment>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {keywords.map((key: any, index: number) => {
              return (
                <tr key={key.id}>
                  <td>
                    {" "}
                    <Button
                      size="sm"
                      variant="outline-danger"
                      className="me-2"
                      onClick={() => {
                        setSelectedKeywordToDelete({
                          keyword: key.keyword,
                          id: key.id,
                        });
                        handleDeleteModalShow();
                      }}
                    >
                      <BsTrashFill />
                    </Button>
                    {key.keyword}
                  </td>
                  <td className="text-center">
                    {getCountryName(key.location)}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot12 > 0 && key.slot12 < 4,
                      "table-warning": key.slot12 > 4 && key.slot12 < 10,
                    })}
                  >
                    {key.slot12}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot11 > 0 && key.slot11 < 4,
                      "table-warning": key.slot11 > 4 && key.slot11 < 10,
                    })}
                  >
                    {key.slot11}
                    {((key.slot11 < key.slot12 && key.slot11 > 0) ||
                      (key.slot11 > 0 && key.slot12 === 0)) && (
                      <BsArrowUpShort color="green" />
                    )}
                    {((key.slot11 > key.slot12 &&
                      key.slot11 > 0 &&
                      key.slot12 > 0) ||
                      (key.slot11 === 0 && key.slot12 > 0)) && (
                      <BsArrowDownShort color="red" />
                    )}
                    {key.slot11 === key.slot12 && key.slot11 > 0 && <BsDash />}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot10 > 0 && key.slot10 < 4,
                      "table-warning": key.slot10 > 4 && key.slot10 < 10,
                    })}
                  >
                    {key.slot10}
                    {((key.slot10 < key.slot11 && key.slot10 > 0) ||
                      (key.slot10 > 0 && key.slot11 === 0)) && (
                      <BsArrowUpShort color="green" />
                    )}
                    {((key.slot10 > key.slot11 &&
                      key.slot10 > 0 &&
                      key.slot11 > 0) ||
                      (key.slot10 === 0 && key.slot11 > 0)) && (
                      <BsArrowDownShort color="red" />
                    )}
                    {key.slot10 === key.slot11 && key.slot10 > 0 && <BsDash />}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot9 > 0 && key.slot9 < 4,
                      "table-warning": key.slot9 > 4 && key.slot9 < 10,
                    })}
                  >
                    {key.slot9}
                    {((key.slot9 < key.slot10 && key.slot9 > 0) ||
                      (key.slot9 > 0 && key.slot10 === 0)) && (
                      <BsArrowUpShort color="green" />
                    )}
                    {((key.slot9 > key.slot10 &&
                      key.slot9 > 0 &&
                      key.slot10 > 0) ||
                      (key.slot9 === 0 && key.slot10 > 0)) && (
                      <BsArrowDownShort color="red" />
                    )}
                    {key.slot9 === key.slot10 && key.slot9 > 0 && <BsDash />}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot8 > 0 && key.slot8 < 4,
                      "table-warning": key.slot8 > 4 && key.slot8 < 10,
                    })}
                  >
                    {key.slot8}
                    {((key.slot8 < key.slot9 && key.slot8 > 0) ||
                      (key.slot8 > 0 && key.slot9 === 0)) && (
                      <BsArrowUpShort color="green" />
                    )}
                    {((key.slot8 > key.slot9 &&
                      key.slot8 > 0 &&
                      key.slot9 > 0) ||
                      (key.slot8 === 0 && key.slot9 > 0)) && (
                      <BsArrowDownShort color="red" />
                    )}
                    {key.slot8 === key.slot9 && key.slot8 > 0 && <BsDash />}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot7 > 0 && key.slot7 < 4,
                      "table-warning": key.slot7 > 4 && key.slot7 < 10,
                    })}
                  >
                    {key.slot7}
                    {((key.slot7 < key.slot8 && key.slot7 > 0) ||
                      (key.slot7 > 0 && key.slot8 === 0)) && (
                      <BsArrowUpShort color="green" />
                    )}
                    {((key.slot7 > key.slot8 &&
                      key.slot7 > 0 &&
                      key.slot8 > 0) ||
                      (key.slot7 === 0 && key.slot8 > 0)) && (
                      <BsArrowDownShort color="red" />
                    )}
                    {key.slot7 === key.slot8 && key.slot7 > 0 && <BsDash />}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot6 > 0 && key.slot6 < 4,
                      "table-warning": key.slot6 > 4 && key.slot6 < 10,
                    })}
                  >
                    {key.slot6}
                    {((key.slot6 < key.slot7 && key.slot6 > 0) ||
                      (key.slot6 > 0 && key.slot7 === 0)) && (
                      <BsArrowUpShort color="green" />
                    )}
                    {((key.slot6 > key.slot7 &&
                      key.slot6 > 0 &&
                      key.slot7 > 0) ||
                      (key.slot6 === 0 && key.slot7 > 0)) && (
                      <BsArrowDownShort color="red" />
                    )}
                    {key.slot6 === key.slot7 && key.slot6 > 0 && <BsDash />}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot5 > 0 && key.slot5 < 4,
                      "table-warning": key.slot5 > 4 && key.slot5 < 10,
                    })}
                  >
                    {key.slot5}
                    {((key.slot5 < key.slot6 && key.slot5 > 0) ||
                      (key.slot5 > 0 && key.slot6 === 0)) && (
                      <BsArrowUpShort color="green" />
                    )}
                    {((key.slot5 > key.slot6 &&
                      key.slot5 > 0 &&
                      key.slot6 > 0) ||
                      (key.slot5 === 0 && key.slot6 > 0)) && (
                      <BsArrowDownShort color="red" />
                    )}
                    {key.slot5 === key.slot6 && key.slot5 > 0 && <BsDash />}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot4 > 0 && key.slot4 < 4,
                      "table-warning": key.slot4 > 4 && key.slot4 < 10,
                    })}
                  >
                    {key.slot4}
                    {((key.slot4 < key.slot5 && key.slot4 > 0) ||
                      (key.slot4 > 0 && key.slot5 === 0)) && (
                      <BsArrowUpShort color="green" />
                    )}
                    {((key.slot4 > key.slot5 &&
                      key.slot4 > 0 &&
                      key.slot5 > 0) ||
                      (key.slot4 === 0 && key.slot5 > 0)) && (
                      <BsArrowDownShort color="red" />
                    )}
                    {key.slot4 === key.slot5 && key.slot4 > 0 && <BsDash />}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot3 > 0 && key.slot3 < 4,
                      "table-warning": key.slot3 > 4 && key.slot3 < 10,
                    })}
                  >
                    {key.slot3}{" "}
                    {((key.slot3 < key.slot4 && key.slot3 > 0) ||
                      (key.slot3 > 0 && key.slot4 === 0)) && (
                      <BsArrowUpShort color="green" />
                    )}
                    {((key.slot3 > key.slot4 &&
                      key.slot3 > 0 &&
                      key.slot4 > 0) ||
                      (key.slot3 === 0 && key.slot4 > 0)) && (
                      <BsArrowDownShort color="red" />
                    )}
                    {key.slot3 === key.slot4 && key.slot3 > 0 && <BsDash />}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot2 > 0 && key.slot2 < 4,
                      "table-warning": key.slot2 > 4 && key.slot2 < 10,
                    })}
                  >
                    {key.slot2}{" "}
                    {((key.slot2 < key.slot3 && key.slot2 > 0) ||
                      (key.slot2 > 0 && key.slot3 === 0)) && (
                      <BsArrowUpShort color="green" />
                    )}
                    {((key.slot2 > key.slot3 &&
                      key.slot2 > 0 &&
                      key.slot3 > 0) ||
                      (key.slot2 === 0 && key.slot3 > 0)) && (
                      <BsArrowDownShort color="red" />
                    )}
                    {key.slot2 === key.slot3 && key.slot2 > 0 && <BsDash />}
                  </td>
                  <td
                    className={classNames("text-center", {
                      "table-success": key.slot1 > 0 && key.slot1 < 4,
                      "table-warning": key.slot1 > 4 && key.slot1 < 10,
                    })}
                  >
                    {key.slot1}{" "}
                    {((key.slot1 < key.slot2 && key.slot1 > 0) ||
                      (key.slot1 > 0 && key.slot2 === 0)) && (
                      <BsArrowUpShort color="green" />
                    )}
                    {((key.slot1 > key.slot2 &&
                      key.slot1 > 0 &&
                      key.slot2 > 0) ||
                      (key.slot1 === 0 && key.slot2 > 0)) && (
                      <BsArrowDownShort color="red" />
                    )}
                    {key.slot1 === key.slot2 && key.slot1 > 0 && <BsDash />}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <div className="small font-italic text-muted">
        <div
          style={{
            width: "15px",
            height: "15px",
            float: "left",
            backgroundColor: "#EAF3ED",
            border: "1px solid #E9ECEF",
            marginTop: "2px",
            marginRight: "5px",
          }}
        ></div>{" "}
        <div>Position from 1 to 3</div>
      </div>
      <div className="small font-italic text-muted">
        <div
          style={{
            width: "15px",
            height: "15px",
            float: "left",
            backgroundColor: "#FFF2CD",
            border: "1px solid #E9ECEF",
            marginTop: "2px",
            marginRight: "5px",
          }}
        ></div>{" "}
        <div>Position from 4 to 10</div>
      </div>
    </div>
  );
};

export default KeywordsTracker;
