import { alertCount } from "./site-alert-slice";
import { db } from "../api/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

export const siteAlertCount = () => {
  return (dispatch: any) => {
    try {
      const q = query(
        collection(db, "site_monitor"),
        where("isDanger", "==", true),
        where("alertToggle", "==", true)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        dispatch(alertCount({ count: querySnapshot.size }));
      });
    } catch (err) {
      console.log(err);
    }
  };
};
